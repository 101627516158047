
import { Pagation } from "@/composables/usePagation";
import useRenderTable from "@/composables/useRenderTable";
import useRequest from "@/composables/useRequest";
import useFetchSearch from "./composables/useFetchSearch";
import { defineComponent, reactive, toRefs, ref, onBeforeUnmount, PropType, watch } from "vue";
import { LANGUAGE, ENVIRONMENT_VARIABLE } from "@/utils";
import useCreateAndUpdate from "./composables/useCreateOrUpdate";
import useSyncUpdate from "./composables/useSyncKey";
import useMessageTip from "@/composables/useMessageTip";
import dayjs from "dayjs";
import { RequestParams } from "@/types/request";
import { getSession, clearSession } from "@/utils";
import { RANGE } from "@/utils/contantOptions";
type Slide = {
  value: number;
  label: string;
};

export default defineComponent({
  name: "appDetail",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    currentEnvId: {
      type: Number,
      default: () => 0
    },
    currentTab: {
      type: String,
      default: () => ""
    },
    allTabSyncEnvList: {
      type: Array as PropType<Slide[]>,
      default: () => []
    }
  },
  setup(props, context) {
    const appDetailMsg = getSession("appDetail", true) as any;
    let currentEnvironment = getSession("currentEnvironment", true) as any;
    const { tipMessage } = useMessageTip();
    const searchParams = reactive({
      keyword: "",
      type: [],
      appId: appDetailMsg.id,
      envId: currentEnvironment.id
    });
    const page = ref(1);

    const tableData = reactive({
      list: [],
      total: 0
    });
    const pageTableRef = ref<any>();
    const stopAutoGetData = ref<boolean>(false);
    // 分页以及获取数据
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any) => {
      stopAutoGetData.value = flag == undefined ? false : true;
      page.value = (pagationParams && pagationParams.pageIndex) || 1;
      const { response } = useRequest(useFetchSearch, params || searchParams);
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count }
      } = await pagation(pagationParams);
      tableData.total = count;
      tableData.list = list;
    };
    // 获取当前环境下的数据
    if (currentEnvironment.name === props.title) {
      getData();
    }
    const handleConditionSearch = async () => {
      // context.emit("update:getTabData", currentEnvironment.id);
      await getData(pageTableRef.value.getCurrentPage(), true, searchParams);
      stopAutoGetData.value = false;
    };
    const handleName = (val: string) => {
      if (!val) handleConditionSearch();
    };
    /**
     * 创建和更新
     */
    const dialogParams = reactive<RequestParams.CreateAndUpdateConfigList>({
      id: 0,
      name: appDetailMsg.name,
      env: "",
      key: "",
      appId: appDetailMsg.id,
      envId: currentEnvironment.id,
      value: "",
      remark: ""
    });
    const {
      rules,
      handleCreateAppShowDialog,
      dialogVisible,
      handleCreateApp,
      formRef,
      handleUpdateApp,
      handleCloseDialog,
      handleDelete,
      validatePass,
      handleDeleteEnvironment
    } = useCreateAndUpdate(getData, pageTableRef, dialogParams, context);

    onBeforeUnmount(() => {
      clearSession("currentEnvironment");
      clearSession("allTabEnvList");
      clearSession("allTabSyncEnvList");
      clearSession("appDetail");
    });

    const handleRang = (val: number) => {
      if (!val) {
        handleConditionSearch();
      }
    };

    // 同步键值
    const dialogSyncParams = reactive<RequestParams.CreateSyncConfigList>({
      name: appDetailMsg.name,
      src_env_id: 1,
      appId: appDetailMsg.id,
      type: 0,
      env: ""
    });
    const { ruleSync, handleCloseSyncDialog, dialogSyncVisible, handleSyncConfig, formRefSync, handleSyncUpdate } = useSyncUpdate(
      getData,
      pageTableRef,
      dialogSyncParams
    );

    watch(
      () => props.currentTab,
      (newVal) => {
        if (newVal === props.title) {
          page.value = 1;
          searchParams.envId = props.currentEnvId;
          getData();
          console.log(222233);
          console.log(pageTableRef.value);
        }
      },
      { immediate: true }
    );
    // 表格环境展示
    const environment = (val: any) => {
      let text = "";
      if (val && val.length) {
        val.forEach((element: any) => {
          text += "/" + element.name.replace("环境", "");
        });
      }
      if (text) text = text.substr(0, text.length - 1);
      return text;
    };
    return {
      handleCloseDialog,
      tipMessage,
      rules,
      handleCreateAppShowDialog,
      handleConditionSearch,
      stopAutoGetData,
      getData,
      ...toRefs(tableData),
      ...toRefs(props),
      pageTableRef,
      searchParams,
      dialogVisible,
      dialogParams,
      handleCreateApp,
      ENVIRONMENT_VARIABLE,
      RANGE,
      LANGUAGE,
      dayjs,
      formRef,
      environment,
      handleUpdateApp,
      handleDelete,

      appDetailMsg,
      handleDeleteEnvironment,
      validatePass,

      dialogSyncParams,
      ruleSync,
      handleCloseSyncDialog,
      dialogSyncVisible,
      formRefSync,
      handleSyncUpdate,
      handleSyncConfig,
      handleName,
      handleRang,
      page
    };
  }
});
